import VueRouter, { Route } from 'vue-router';
import { getCurrentInstance, computed, Ref } from "@vue/composition-api";

export function useRouter(): VueRouter {
  const instance = getCurrentInstance()?.proxy
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  return instance?.$router!!
}

export function useRoute(): Ref<Route> {
  const instance = getCurrentInstance()?.proxy
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-extra-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  return computed(() => instance?.$route!!)
}