import { extend, localize } from 'vee-validate';
import { required, email, min_value as minValue, max_value as maxValue, max, min } from 'vee-validate/dist/rules';
import { ValidationRuleSchema } from 'vee-validate/dist/types/types';
import i18n from "@/i18n";
import { watchEffect } from '@vue/composition-api';

// Passt eine Regel für ein Zahlenfeld so an, dass sie auch mit Kommas zurechtkommt
function internationalizeNumberRule(rule: ValidationRuleSchema): ValidationRuleSchema {
  return {
    ...rule, validate(value: any, params: any) {
      return rule.validate!(value.toString().replace(",", "."), params);
    }
  }
}

// Übersetzungen zur Laufzeit laden
watchEffect(async () => {
  const locale = i18n.locale
  try {
    const messages = await import(`vee-validate/dist/locale/${locale}.json`)
    localize({
      [locale]: messages
    })
    localize(i18n.locale)
  } catch(e) { 
    console.warn(`Unable to load validation messages for locale ${locale}`, e)
  }
})

// Regeln definieren
extend('email', email);
extend('required', required);
extend('min_value', internationalizeNumberRule(minValue));
extend('max_value', internationalizeNumberRule(maxValue));
extend('min', min)
extend('max', max)