import { keycloak } from '@/auth'
import { isAuthorized } from '@/common/authorization'
import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import i18n from '@/i18n'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    beforeEnter(_to, _from, next) {
      const sub = keycloak.userInfo?.subsidiary_code
      if ( isAuthorized('sales_requests' , "show", sub )) {
        next({ name: "requests", params: { subsidiaryCode: sub } })
      } else {
        const cn = keycloak.userInfo?.customer_number
        if (sub && cn) {
          next({ name: "customer_orders", params: { subsidiaryCode: sub, customerId: cn } })
        } else {
          next({ name: "error" })
        }
      }
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/requests',
    name: 'requests',
    components: {
      default: () => import('../views/sales/Requests.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_requests' , "show_all", route.params.subsidiaryCode )
    },
    props: {
      default: route => ({
        ...route.params
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/requests/new',
    name: 'createRequest',
    components: {
      default: () => import('../views/sales/Create.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_requests' , "create_request", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params,
        documentType: "request",
        editMode: true
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/requests/:id',
    name: 'request',
    components: {
      default: () => import('../views/sales/Request.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_requests' , "show", route.params.subsidiaryCode )
    },
    props: {
      default: route => ({
        ...route.params
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/quotes',
    name: 'quotes',
    components: {
      default: () => import('../views/sales/Quotes.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_quotes' , "show_all", route.params.subsidiaryCode)
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/invoices',
    name: 'invoices',
    components: {
      default: () => import('../views/sales/Invoices.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    props: {
      default: route => ({
        ...route.query,
        ...route.params,
      })
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_invoices' , "show_all", route.params.subsidiaryCode)
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/orders',
    name: 'orders',
    components: {
      default: () => import('../views/sales/Orders.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_orders' , "show_all", route.params.subsidiaryCode)
    }
  },

  {
    path: '/subsidiaries/:subsidiaryCode/quotes/new',
    name: 'createQuote',
    components: {
      default: () => import('../views/sales/Create.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_quotes' , "create_quote", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params,
        documentType: "quote",
        editMode: true
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/quotes/:id',
    name: 'quote',
    components: {
      default:  () => import('../views/sales/Quote.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_quotes' , "show", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/orders/:id',
    name: 'order',
    components: {
      default: () => import('../views/sales/Order.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_orders' , "show", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params
      })
    }
  },

  {
    path: '/subsidiaries/:subsidiaryCode/orders/new',
    name: 'createOrder',
    components: {
      default: () => import('../views/sales/Create.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_orders' , "create", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params,
        documentType: "order"
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/invoices/:id',
    name: 'invoice',
    components: {
      default: () => import('../views/sales/Invoice.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_invoices' , "show", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params
      })
    }
  },

  {
    path: '/subsidiaries/:subsidiaryCode/invoices/new',
    name: 'createInvoice',
    components: {
      default: () => import('../views/sales/Create.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('sales_invoices' , "create", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params,
        documentType: "invoice"
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/settings',
    name: 'settings',
    components: {
      default: () => import('../views/admin/Settings.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('subsidiary_settings' , "show", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        ...route.params,
        documentType: "order"
      })
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/bsc/:year/:month',
    name: 'balancedScorecard',
    components: {
      default: () => import('../views/admin/BalancedScorecard.vue'),
      header: () => import('../views/SalesHeader.vue')
    },
    meta: {
      isPermitted: (route: Route) => isAuthorized('balanced_scorecard' , "show", route.params.subsidiaryCode)
    },
    props: {
      default: route => ({
        subsidiaryCode: route.params.subsidiaryCode,
        year: parseInt(route.params.year),
        month: parseInt(route.params.month)
      })
    }
  },

  // ========== Kundenansicht ==========

  {
    path: '/me/*',
    name: 'customer_shortcut',
    beforeEnter(to, _from, next) {
      const subsidiary = keycloak.userInfo?.subsidiary_code
      const customerNumber = keycloak.userInfo?.customer_number
      if (subsidiary != null && customerNumber != null) {
        next(`/subsidiaries/${subsidiary}/customers/${customerNumber}/${to.params.pathMatch}`)
      } else {
        next({ name: "error", query: { error: "unauthorized" } })
      }
    }
  },

  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/quotes',
    name: 'customer_quotes',
    components: {
      default: () => import('../views/customer/Quotes.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_quotes' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/quotes/:id',
    name: 'customer_quote',
    components: {
      default: () => import('../views/customer/Quote.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_quotes' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/requests',
    name: 'customer_requests',
    components: {
      default: () => import('../views/customer/Requests.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_requests' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/requests/:id',
    name: 'customer_request',
    components: {
      default: () => import('../views/customer/Request.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_requests' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/orders',
    name: 'extern_orders',
    beforeEnter(_to, _from, next) {
      const sub = keycloak.userInfo?.subsidiary_code
      if (isAuthorized("sales_view", "show_subsidiary", sub)) {
        next({ name: "orders", params: { subsidiaryCode: sub } })
      } else {
        const cn = keycloak.userInfo?.customer_number
        if (sub && cn) {
          next({ name: "customer_orders", params: { subsidiaryCode: sub, customerId: cn } })
        } else {
          next({ name: "error" , query: { error: "unauthorized" }})
        }
      }
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/orders',
    name: 'customer_orders',
    components: {
      default: () => import('../views/customer/Orders.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_orders' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/subsidiaries/:subsidiaryCode/customers/:customerId/orders/:id',
    name: 'customer_order',
    components: {
      default: () => import('../views/customer/Order.vue'),
      header: () => import('../views/CustomerHeader.vue')
    },
    props: {
      default: route => ({
        ...route.params
      })
    },
    meta: {
      isPermitted: () => isAuthorized('customer_orders' , "show"),
      title: () => i18n.t('customer.title')
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue'),
    props: route => ({
      error: route.query.error ?? "general"
    })
  },
  {
    path: '*',
    name: 'fallback',
    redirect: { name: 'error', query: { error: "notFound" } }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  if (to.name != "error" && !isAuthorized("app", "use")) return next({ name: 'error', query: { error: "unauthorized" } })
  if (to.meta?.isPermitted != null) {
    if (to.meta.isPermitted(to) !== true) next({ name: 'error' })
  }
  next()
}
)