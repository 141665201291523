import Vue from 'vue'
import VueI18n, { LocaleMessages, DateTimeFormats, DateTimeFormat } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// Standardmäßig fällt vue-i18n auf die Fallback-Locale zurück, wenn für die
// locale des Users keine Datumsformate definiert sind. Dabei wird nicht nur
// das Datumsformat sondern auch die locale verändert.
// Um dieses Standardverhalten zu umgehen verwende ich hier einen Trick,
// der mittels eines Proxy dem Framework vorgaukelt, für jede Sprache wären
// Datumsformate definiert
function datetimeFormatsWithFallback(dateTimeFormats: DateTimeFormats, defaults: DateTimeFormat) {
  const DEFAULT_DATETIME_LOCALE = Symbol("DEFAULT_DATETIME_LOCALE")
  return new Proxy({
    [DEFAULT_DATETIME_LOCALE]: defaults,
    ...dateTimeFormats
  } as Record<string | typeof DEFAULT_DATETIME_LOCALE, DateTimeFormat>, {
    get(target, key: string) {
      // Wenn der Key ein normaler locale-String ist und nicht vorhanden,
      // kopiere Standardformate
      if (!key.toString().startsWith("_") && typeof key != 'symbol' && !target[key]) {
        target[key] = JSON.parse(JSON.stringify(target[DEFAULT_DATETIME_LOCALE]))
      }
      return target[key]
    }
  })
}

const dateTimeFormats = datetimeFormatsWithFallback(
  // Sprachspezifische Datumsformate
  {
    en: {
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    }
  },
  // Fallback-Datumsformate
  {
    long: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
)

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats
})