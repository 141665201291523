import { DirectiveOptions } from 'vue/types/umd';

const focus: DirectiveOptions = {
  inserted(el, binding) {
    if (binding.value !== false) setTimeout(() => {
      const element: HTMLElement | null = binding.modifiers.input ? el.querySelector("input") : el
      element?.focus()
      if (binding.modifiers.select && element instanceof HTMLInputElement) element.select()
    }, 0);
  }
}

export default focus