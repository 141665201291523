import { initPolyfills } from "./polyfills"
import Vue from 'vue'
import "@/composition-api"
import App from '@/App.vue'
import router from './router'
import { keycloak } from './auth'
import i18n from './i18n'
import { eeposUiPlugin } from '@intern/eepos-ui-vue2'
import BootstrapVue from 'bootstrap-vue'
import focus from '@/directives/focus'
import './main.scss'
import "@/common/vee-validate"

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(eeposUiPlugin)
Vue.directive('focus', focus)


  ; (async () => {
    try {
      const authenticated = await keycloak.init()
      if (!authenticated) await keycloak.login()
      i18n.locale = keycloak.userInfo?.locale
      await initPolyfills()

      const app = new Vue({
        router,
        i18n,
        render: h => h(App)
      });

      app.$mount('#app')
    } catch(e) {
      alert(e.message)
    }
})()