




















import { keycloak } from "./auth";
import { useCurrentUser } from "./common/current-user";
import { computed, ref, watchEffect } from "@vue/composition-api";
import { useRoute } from './common/routing';
import i18n from './i18n';

export default {
  setup() {
    const { user } = useCurrentUser();
    const route = useRoute()
    const title = computed(() => route.value?.meta?.title ? route.value.meta.title() : i18n.t("general.title"))
    const token = ref(keycloak.token)
    keycloak.onTokenUpdate = (newToken) => token.value = newToken
    watchEffect(() => {
      document.title = title.value
    })
    return {
      isTestingSystem: computed(() => process.env.VUE_APP_STAGING == "true"),
      title,
      token,
      userName: computed(() => user.value?.name),
      onLogoutClick: () => keycloak.logout()
    };
  },
};
