import { keycloak } from "../auth";
import { ref, computed } from '@vue/composition-api';


export function useCurrentUser() {
    const user = ref<typeof keycloak["userInfo"]>()

    keycloak.init().then(authenticated => {
        if (authenticated) user.value = keycloak.userInfo
    })

    const roles = computed(() => user.value?.resource_access?.salesman?.roles ?? [] )

    const subsidiary = computed<string|null>(() => user.value?.subsidiary_code ?? null)

    return {
        user,
        roles,
        subsidiary
    }
}