import { useCurrentUser } from '@/common/current-user'

const { roles, subsidiary } = useCurrentUser()

type Role = "user" | "salesperson" | "admin" | "subsidiary-admin" | "license-basic" | "developer"

function isRole(role: Role): boolean {
    return roles.value.includes(role) ?? false
}

function isAdminOfSubsidiary(subsidiaryCode: string): boolean {
    return isRole("subsidiary-admin") && ((subsidiary.value == subsidiaryCode) ?? false)
}

function isSalespersonOfSubsidiary(subsidiaryCode: string): boolean {
    return isRole("salesperson") && ((subsidiary.value == subsidiaryCode) ?? false)
}
interface ResourceDefinition {
    [resource: string]: {
        actions: {
            [action: string]: {
                checkPermission: (...options: any) => boolean;
            };
        };
    };
}

type Resource<D extends ResourceDefinition> = keyof D
type Action<D extends ResourceDefinition, R extends Resource<D>> = keyof D[R]['actions']
type PermissionCheckOptions<D extends ResourceDefinition, R extends Resource<D>, A extends Action<D, R>> = Parameters<D[R]['actions'][A]['checkPermission']>


type ResourceHandler<D extends ResourceDefinition> = {
    checkPermission: <R extends Resource<D>, A extends Action<D, R>>(resource: R, action: A, ...options: PermissionCheckOptions<D, R, A>) => boolean;
}

function defineResources<D extends ResourceDefinition>(definitions: D): ResourceHandler<D> {
    return {
        checkPermission: (resource, action, options) => {
            return definitions[resource].actions[action as any].checkPermission(options)
        }
    }
}


const resources = defineResources({
    'app': {
        actions: {
            'use': {
                checkPermission() {
                    return isRole("user")
                }
            }
        }
    },
    'subsidiary_settings': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isAdminOfSubsidiary(subsidiaryCode)
                }
            }
        }
    },
    'balanced_scorecard': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isAdminOfSubsidiary(subsidiaryCode)
                }
            }
        }
    },
    // =========== Verkäuferansicht ===========
    // Allgemeine Abfrage... subsidiary in jeder sales_view resource prüfen?
    'sales_view': {
        actions: {
            'show_subsidiary': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'switch_subsidiary': {
                checkPermission() {
                    return false
                }
            },
            'edit_item_discount': {
                checkPermission() {
                    return isRole('salesperson')
                }
            }

        }
    },
    'sales_requests': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'show_all': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'edit': { // hier auch noch subsidiary code abfragen?
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'create_request': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'create_quote': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'resubmit': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('developer')
                }
            },
        }
    },
    'sales_quotes': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'show_all': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'edit': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'create_quote': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
        }
    },
    'sales_invoices': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'show_all': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'create': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'edit': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
            'create_invoice': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('license-basic')
                }
            },
        }
    },
    'sales_orders': {
        actions: {
            'show': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'show_all': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'edit': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'create': { // Nicht verwendet
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode)
                }
            },
            'resubmit': {
                checkPermission(subsidiaryCode: string) {
                    return isSalespersonOfSubsidiary(subsidiaryCode) && isRole('developer')
                }
            },
        }
    },
    // =========== Kundenansicht ===========
    'customer_requests': {
        actions: {
            'show': {
                checkPermission() {
                    return isRole('user')
                }
            },
            'edit': {
                checkPermission() {
                    return isRole('user')
                }
            },
            'create_request': {
                checkPermission() {
                    return isRole('user')
                }
            },
        }
    },
    'customer_quotes': {
        actions: {
            'show': {
                checkPermission() {
                    return isRole('user')
                }
            },
        }
    },
    'customer_orders': {
        actions: {
            'show': {
                checkPermission() {
                    return isRole('user')
                }
            },
        }
    },
})


export const isAuthorized: (typeof resources)["checkPermission"] = (resource, action, ...options) => {
    if (isRole('admin')) return true
    return resources.checkPermission(resource, action, ...options)
}
