import {shouldPolyfill as shouldPolyfillGetCannonicalLocales} from '@formatjs/intl-getcanonicallocales/should-polyfill'
import {shouldPolyfill as shouldPolyfillLocale} from '@formatjs/intl-locale/should-polyfill'
import {shouldPolyfill as shouldPolyfillDisplaynames} from '@formatjs/intl-displaynames/should-polyfill'

import i18n from "./i18n"

export const initPolyfills = async () => {
  const locale = i18n.locale

  if (shouldPolyfillGetCannonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill')
  }
  if (shouldPolyfillDisplaynames()) {
    await import('@formatjs/intl-displaynames/polyfill')
    try {
      switch(locale) {
        // Statt alle Sprachen zu bundlen, werden hier nur ein paar feste Sprachen geladen, weil sonst der build unterträglich langsam wird
        default:
          await import("@formatjs/intl-displaynames/locale-data/en")
          break
        case 'de':
          await import("@formatjs/intl-displaynames/locale-data/de")
          break
        case 'fr':
          await import("@formatjs/intl-displaynames/locale-data/fr")
          break
        case 'es':
          await import("@formatjs/intl-displaynames/locale-data/es")
          break
      }
      
      
    } catch(e) {
      console.error(e.message)
    }
  }
}